import logo from "./logo.JPG";
import "./App.css";
import React from "react";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Bientôt Disponible </h1>
        <div>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "20vw", height: "auto" }}
          />
        </div>

        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        ></a>
        <h1>Coming Soon </h1>
      </header>
    </div>
  );
}

export default App;
